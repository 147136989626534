<template>
    <div class="addProperty">
        <!--        导航-->
        <rxNavBar title="添加物业交割单"></rxNavBar>
        <!--上传图片loading-->
        <loading v-if="loadingFlag"></loading>
        <!--        电表交割-->
        <div class="waterMeterTitle">
            <span class="redText">电表交割</span>
        </div>
        <!--       电表交割信息 -->
        <div class="waterMeterDeliveryInfo">
            <!--            联合收费处-->
            <div class="cashier">
<!--                <div :class="dotOne3==''?grayDot:redDot"></div>-->
                <span  class="cashierTextg" @click="isShowElectric=!isShowElectric">
                    {{type3.name}}
                </span>
                <img src="../../../assets/images/triangle.png" :class="!isShowElectric?'downImage':'upImage'">
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotOne3" type="text" placeholder="输入电表号"  >
            </div>
            <van-popup v-model="isShowElectric" position="bottom">
                <van-picker show-toolbar value-key="name" :columns="list3"
                            @cancel="isShowElectric = false" @confirm="selectElecType" />
            </van-popup>
            <!--            分割线1-->
            <div class="ruleOne"></div>
            <!--            电表底数-->
            <div class="cashier">
<!--                <div :class="dotTwo3==''?grayDot:redDot"></div>-->
                <span class="cashierText">电表底数</span>
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotTwo3" type="number" placeholder="输入电表底数" >
            </div>
            <!--            分割线2-->
            <div class="ruleOne"></div>
            <!--            上传图片-->
            <van-uploader v-model="waterMeterFileList3"
                          class="upLoadImg"
                          :max-count="1"
                          accept="image/*"
                          preview-size="93px"
                          capture="camera"
                          :after-read="afterReadTest"/>
            <div class="upLoadText">
                <span>*请务必上传</span>
            </div>
        </div>
        <!--        水表交割-->
        <div class="waterMeterTitle">
            <span class="redText">水表交割</span>
        </div>
        <!--       水表交割信息 -->
        <div class="waterMeterDeliveryInfo">
            <!--            联合收费处-->
            <div class="cashier">
<!--                <div :class="dotOne==''?grayDot:redDot"></div>-->
                <span  class="cashierTextg11" @click="isShowWater=!isShowWater">
                    {{type1.name}}
                </span>
                <img src="../../../assets/images/triangle.png" :class="!isShowWater?'downImage':'upImage'">
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotOne" type="text" placeholder="输入水表号"  >
            </div>
            <van-popup v-model="isShowWater" position="bottom">
                <van-picker show-toolbar value-key="name" :columns="list1"
                            @cancel="isShowWater = false" @confirm="selectWaterType" />
            </van-popup>
            <!--            分割线1-->
            <div class="ruleOne"></div>
            <!--            水表底数-->
            <div class="cashier">
<!--                <div :class="dotTwo==''?grayDot:redDot"></div>-->
                <span class="cashierText">水表底数</span>
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotTwo" type="number" placeholder="输入水表底数" >
            </div>
            <!--            分割线2-->
            <div class="ruleOne"></div>
            <!--            上传图片-->
            <van-uploader v-model="waterMeterFileList"
                          class="upLoadImg"
                          :max-count="1"
                          accept="image/*"
                          preview-size="93px"
                          capture="camera"
                          :after-read="afterReadTest"/>
            <div class="upLoadText">
                <span>*请务必上传</span>
            </div>
        </div>

        <!--        燃气表交割-->
        <div class="waterMeterTitle">
            <span class="redText">燃气表交割</span>
        </div>
        <!--       燃气 表交割信息 -->
        <div class="waterMeterDeliveryInfo">
            <!--            联合收费处-->
            <div class="cashier">
<!--                <div :class="dotOne4==''?grayDot:redDot"></div>-->
                <span  class="cashierTextg" @click="isShowGas=!isShowGas">
                    {{type4.name}}
                </span>
                <img src="../../../assets/images/triangle.png" :class="!isShowGas?'downImage':'upImage'">
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotOne4" type="text" placeholder="输入燃气表号"  >
            </div>
            <van-popup v-model="isShowGas" position="bottom">
                <van-picker show-toolbar value-key="name" :columns="list4"
                            @cancel="isShowGas = false" @confirm="selectGasType" />
            </van-popup>
            <!--            分割线1-->
            <div class="ruleOne"></div>
            <!--            燃气表底数-->
            <div class="cashier">
<!--                <div :class="dotTwo4==''?grayDot:redDot"></div>-->
                <span class="cashierText">燃气表底数</span>
                <span class="rule">|</span>
                <input class="inputNum" v-model="dotTwo4" type="number" placeholder="输入燃气表底数" >
            </div>
            <!--            分割线2-->
            <div class="ruleOne"></div>
            <!--            上传图片-->
            <van-uploader v-model="waterMeterFileList4"
                          class="upLoadImg"
                          :max-count="1"
                          accept="image/*"
                          preview-size="93px"
                          capture="camera"
                          :after-read="afterReadTest"/>
            <div class="upLoadText">
                <span>*请务必上传</span>
            </div>
        </div>

        <div class="addPropertyDetail">
            <textarea class="textareaRemarks" placeholder="备注" v-model="description"></textarea>
        </div>

        <!--        房屋配置标题    引用水表交割标题字体样式-->
        <div class="waterMeterTitle">
            <span class="redText">物品交割</span>
        </div>
        <!--       房屋配置信息 -->
        <div class="houseConfiguration" v-for="(item , i) in houseConfigurationList" :key="i">
            <!--            红色取消按钮-->
            <div class="cancelBtn" @click="cancelBtn(i)">
                <div class="cancelImg"></div>
            </div>
            <!--            配置详情-->
            <div class="houseDetail">
                <!--             名称-->
                <div class="configuration">
<!--                    <div :class="houseConfigurationList[i].type==''?grayDot:redDot" ></div>-->
                    <span class="configurationName" >类型</span>
                    <span class="rule" >|</span>
                    <span class="configurationSelect" @click="chooseOne(i)">
                        {{houseConfigurationList[i].goodsAllocationClassName}}
                    </span>
                    <img src="../../../assets/images/triangle.png" :class="!isShowGoodsTitle?'downImage':'upImage'">
                </div>
                <van-popup v-model="isShowGoodsTitle" position="bottom">
                    <van-picker show-toolbar value-key="goodsAllocationClassName" :columns="typeInfoList"
                                @cancel="isShowGoodsTitle = false" @confirm="selectGoodsTitleType" />
                </van-popup>
                <!--            分割线3-->
                <div class="ruleOne"></div>
                <div class="configuration">
<!--                    <div :class="houseConfigurationList[i].name==''?grayDot:redDot" ></div>-->
                    <span class="configurationName" >名称</span>
                    <span class="rule" >|</span>
                    <span class="configurationSelect" @click="showGoodsName(i)">
                        {{houseConfigurationList[i].goodsName}}
                    </span>
                    <img src="../../../assets/images/triangle.png" :class="!isShowGoodsTitle?'downImage':'upImage'">
                </div>
                <van-popup v-model="isShowGoogsName" position="bottom">
                    <van-picker show-toolbar value-key="itemName" :columns="houseConfigurationList[index].configurationInfoList"
                                @cancel="isShowGoogsName = false" @confirm="selectGoodsNameType" />
                </van-popup>
                <!--            分割线3-->
                <div class="ruleOne"></div>
                <!--            数量-->
                <div class="configuration">
<!--                    <div :class="houseConfigurationList[i].count==''?grayDot:redDot"></div>-->
                    <span class="configurationCount">数量</span>
                    <span class="rule">|</span>
                    <div class="placeholderDiv">
                    </div>
                    <div style="margin-left: 1.4rem;">
                        <input type="text" class="count" v-model="houseConfigurationList[i].count" disabled="disabled">
                    </div>
                </div>
                <!--            分割线4-->
                <div class="ruleOne"></div>
                <!--            上传图片-->
                <div class="upLoadImg" >
                    <van-uploader v-model="houseConfigurationList[i].houseFileList" multiple
                                  accept="image/*"
                                  preview-size="80px"
                                  :after-read="afterReadTest"
                                  style="padding-left: 0.25rem"/>
                </div>
                <div class="upLoadText">
                    <span >*请务必上传</span>
                </div>
                <!--            分割线5-->
                <div class="ruleOne"></div>
                <!--                备注-->
                <textarea class="textareaRemarks" v-model="houseConfigurationList[i].desc" placeholder="描述"></textarea>
                <div>

                </div>
            </div>
        </div>
        <!--        新增条目信息-->
        <div class="newAdd" @click="addInfo()">
            <u >+ 新增条目</u>
        </div>
        <!--            分割线6-->



        <!--        上传视频-->
        <div class="waterMeterTitle" v-if="mold==1">
            <span class="redText">上传视频</span>
        </div>
        <!--       燃气 表交割信息 -->
        <div class="waterMeterDeliveryInfoVideo" v-if="mold==1">

            <!--            上传视频-->
            <van-uploader v-model="waterMeterFileList5" multiple
                          :class="uploadVedio?'upLoadImg-vedioHide':'upLoadImg'"
                          :max-count="1"
                          accept="video/*"
                          preview-size="93px"
                          :before-read="beforeReadTestVideo"
                          :after-read="afterReadTestVideo" v-show="!videourl">
            </van-uploader>
              <video
                style="height:120px;object-fit:cover;" class="upLoadImg"   controls="controls" loop="loop"
                :src="videourl"
                v-if="videourl"
              ></video>
              <!--<span class="remove_video" v-if="videourl" @click="removeVideo" style="position:absolute;left:215px;top:20px;font-size:15px">X</span>-->
              <van-loading size="24px" type="spinner" vertical v-if="uploadVedio">已上传 {{uploadProgressBar}}%</van-loading>
            <div v-if="videourl" class="deleteVideo">
                <span  @click="removeVideo">删除视频</span>
            </div>
            <div  class="upLoadText">
                <span >*请务必上传</span>
            </div>
        </div>
        
        <!--        保存按钮      ||!countTemp||!selectTemp-->
<!--        <button :class="isClick() ? redsaveBtn : graySaveBtn "-->
<!--                :disabled="!isClick()"-->
<!--                @click="saveInfo">-->
<!--            保存-->
<!--        </button>-->
        <button class=" redsaveBtn " :disabled="saveingStatus" @click="saveInfo">{{saveingStatus == true ? '保存中': '保存'}}</button>
    </div>


</template>

<script>
import * as OSS from 'ali-oss'

import axios from 'axios'
    import { NavBar,Uploader,Toast ,Picker, Popup,Loading } from 'vant';
    import {
        addContractproperty,
        base64Upload, findContractproperty, getMeterNumberAndMore, queryAllocation,
        queryAllocationClass,updateVideo,delVideo
    } from "../../../getData/getData";
    import {checkAndroid, checkIOS, getStaffId,dealImage, responseUtil} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    /********判断H5页面是否在web容器中 start*********/
    function openInWebview () {/* 返回true或false; */
        var ua = navigator.userAgent.toLowerCase();
        if (ua.match(/MicroMessenger/i) == 'micromessenger') { // 微信浏览器判断
            return false;
        } else if (ua.match(/QQ/i) == 'qq') { // QQ浏览器判断
            return false;
        } else if (ua.match(/WeiBo/i) == "weibo") {
            return false;
        } else {
            if (ua.match(/Android/i) != null) {
                return ua.match(/browser/i) == null;
            } else if (ua.match(/iPhone/i) != null) {
                return ua.match(/safari/i) == null;
            } else {
                return (ua.match(/macintosh/i) == null && ua.match(/windows/i) == null);
            }
        }
    }
    /********判断H5页面是否在web容器中 start*********/


    export default {
        components: {
            [NavBar .name]:NavBar,
            [Uploader .name]:Uploader,
            [Picker.name]: Picker,
            [Popup.name]: Popup,
            [Toast .name]:Toast,
            rxNavBar,
            [Loading .name]:Loading,
        },
        data() {
            return {
                setHouse_id : '',
                roomHouse_id : '',
                saveingStatus: false,   //控制保存按钮状态，防止二次点击  默认为保存，可点击
                acceptType:'image/*',
                loadingFlag:false,//上传图片loading
                isShowWater:false,//是否显示水表号
                isShowHotWater:false,//是否显示热水表号
                isShowElectric:false,//是否显示电表号
                isShowGas:false,//是否显示煤气号
                isShowGoodsTitle:false,//显示商品标题
                isShowGoodsName:false,//显示商品名称
                isShowGoogsName:false,//是否显示商品具体名称
                index:0,//记录物品数组下标
                contractId: '',
                type:'',
                contracthistoryId:'',
                propertyType:'',
                list1: [
                    {name: '水表号' , value: '0'},
                    {name: '联合收费处' , value: '1'}
                ],
                type1: {name: '水表号' , value: '0'},
                //表单前面的小点点
                dotOne : '',
                dotTwo : '',
                //水表图片的List
                waterMeterFileList: [],
                list2: [
                    {name: '热水表号' , value: '0'},
                    {name: '联合收费处' , value: '1'}
                ],
                type2: {name: '热水表号' , value: '0'},
                dotOne2 : '',
                dotTwo2 : '',
                waterMeterFileList2: [],
                list3: [
                    {name: '电表号' , value: '0'},
                    {name: '联合收费处' , value: '1'}
                ],
                type3: {name: '电表号' , value: '0'},
                dotOne3 : '',
                dotTwo3 : '',
                waterMeterFileList3: [],
                list4: [
                    {name: '燃气表号' , value: '0'},
                    {name: '联合收费处' , value: '1'}
                ],
                type4: {name: '燃气表号' , value: '0'},
                dotOne4 : '',
                dotTwo4 : '',
                description:'',
                waterMeterFileList4: [],
                waterMeterFileList5: [],
                countTemp:'',
                selectTemp: '',
                show : false,
                disabled : false,
                dotThree : true,
                dotFour : true,
                grayDot : 'grayDot',
                redDot : 'redDot',
                //房屋配置信息里名称的select
                // select :'1',
                typeInfoList: [],

                //房屋配置信息里数量的变量
                // count: '1',
                //房屋配置的div
                houseConfigurationList : [
                    {
                        count:1,
                        type: '',
                        goodsAllocationClassName:'',//商品标题
                        goodsName:'',//商品名称
                        name:'',
                        configurationInfoList :[],
                        //房屋图片的List
                        houseFileList : [],
                        desc: ''
                    }
                ],
                //    保存按钮变色
                graySaveBtn : 'graySaveBtn',
                redsaveBtn : 'redsaveBtn',
                //winWidth: window.innerWidth,
                //winHeight: window.innerHeight,
                videourl:'',
                videoId:'',   //要删除的视频id
                mold:'',        //1代表租客，2代表业主
                uploadVedio:false,    //是否上传了视频
                uploadProgressBar:0,    //上传视频的进度

            }
        },
        created() {
            if (openInWebview()) {
                if(checkIOS()){
                    this.acceptType = 'image/*';
                }else if(checkAndroid()){
                    this.acceptType = 'video/*';
                }else{
                    this.acceptType = 'image/*';
                }
            }
        },
        mounted() {
            this.initData()
            this.roomHouse_id = this.$route.query.roomHouse_id || ''   //用于初始化房屋的水表号等
            this.setHouse_id = this.$route.query.setHouse_id || ''
            this.contractId = this.$route.query.contractId || ''
            this.contractPropertyId = this.$route.query.contractPropertyId || ''
            //0代表添加，1代表修改
            this.type = this.$route.query.type
            this.contracthistoryId = this.$route.query.contracthistoryId
            //1 表明租约变更了
            this.propertyType =this.$route.query.propertyType
            this.mold=this.$route.query.mold
            if( this.type =='1'){
                this.initContractproperty()
            }else if(this.type =='0' && this.setHouse_id != ''){
                this.getMeterNumberAndMore();
            }

        },
        methods : {
            removeVideo(){
              this.waterMeterFileList5=[]
              this.videourl=''
              if(!this.videoId){
                  return
              }
            var that=this
            var initData={}
            initData.id=that.videoId
            delVideo(initData).then(function (response) {
                responseUtil.dealResponse(that, response, () => {
                })
            })
            },

            // 初始化获取水表号等
            getMeterNumberAndMore(){
                let that = this;
                let initData = {};
                initData.setHouse_id = that.setHouse_id
                getMeterNumberAndMore(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let wattMeterInfo = response.data.data.wattMeterInfo.data  //电表
                        let waterMeterInfo = response.data.data.waterMeterInfo.data  //水表
                        let gasMeterInfo = response.data.data.gasMeterInfo.data   //燃气表

                        if(wattMeterInfo != undefined){
                            that.dotOne3 = wattMeterInfo.equipmentCode
                            that.selectElecType(that.getArrValuesByIndex(that.list1,wattMeterInfo.equipmentCodeType))

                        }

                        if(waterMeterInfo != undefined){
                            that.dotOne = waterMeterInfo.equipmentCode
                            that.selectWaterType(that.getArrValuesByIndex(that.list1,waterMeterInfo.equipmentCodeType))
                        }

                        if(gasMeterInfo != undefined){
                            that.dotOne4 = gasMeterInfo.equipmentCode
                            that.selectGasType(that.getArrValuesByIndex(that.list1,gasMeterInfo.equipmentCodeType))
                        }
                    })
                })
            },

            //根据id或者value 获取对应数组的值
            getArrValuesByIndex(arr,index){
                for(let i=0;i<arr.length;i++){
                    if(arr[i].value==index){
                        return arr[i]
                    }
                }
                return ''
            },
            //水表
            selectWaterType(item){
                this.type1 = item
                this.isShowWater = false
            },
            //热水表
            selectHotWaterType(item){
                this.type2 = item
                this.isShowHotWater = false
            },
            //电表
            selectElecType(item){
                this.type3 = item
                this.isShowElectric = false
            },
            //煤气表
            selectGasType(item){
                this.type4 = item
                this.isShowGas = false
            },
            //商品分类选择
            chooseOne(i){
                this.index = i
                this.isShowGoodsTitle = !this.isShowGoodsTitle
            },
            showGoodsName(i){
                this.index = i
                this.isShowGoogsName = !this.isShowGoogsName
            },
            //选择商品分类下拉
            selectGoodsTitleType(item){
                this.houseConfigurationList[this.index].goodsAllocationClassName = item.goodsAllocationClassName
                this.houseConfigurationList[this.index].type = item.id
                //清空原有商品的名字
                this.houseConfigurationList[this.index].goodsName = ''
                this.selectChange(this.index)
                this.isShowGoodsTitle = false
            },
            //选择商品具体名字
            selectGoodsNameType(item){
                this.houseConfigurationList[this.index].goodsName = item.itemName
                this.houseConfigurationList[this.index].name = item.id
                this.isShowGoogsName = false
            },
            //物业交割单数据加载
            initContractproperty(){
                let that = this
                let initData={}
                let contractId = that.contractId
                let contractHistoryId = that.contracthistoryId
                let proType = that.propertyType
                initData.contractPropertyId = this.contractPropertyId
                if(proType=='1'){
                    initData.contractId = contractId
                } else {
                    initData.contractId = contractId
                    initData.contracthistoryId = contractHistoryId
                    initData.isByContractHistory = true
                }
                findContractproperty(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        let info = response.data.data.contractProperty

                        // 水表号
                        that.dotOne = info.waterMeterCode
                        // 水表底数
                        that.dotTwo = info.waterMeterNumber
                        if(info.waterMeterCodeType=='1'){
                            that.type1.name = '联合收费处'
                            that.type1.value = '1'
                        } else {
                            that.type1.name = '水表号'
                            that.type1.value = '0'
                        }
                        //水表底数图片
                        if(info.waterMeterUrl){
                            that.waterMeterFileList.push({content:info.waterMeterUrl,url:info.waterMeterUrl})
                        }
                        // 热水表号
                        that.dotOne2 = info.hotWaterMeterCode
                        // 热水表底数
                        that.dotTwo2 = info.hotWaterMeterNumber
                        if(info.hotWaterMeterCodeType=='1'){
                            that.type2.name = '联合收费处'
                            that.type2.value = '1'
                        } else {
                            that.type2.name = '热水表号'
                            that.type2.value = '0'
                        }
                        // 热水表底数图片
                        if(info.hotWaterMeterUrl){
                            that.waterMeterFileList2.push({content:info.hotWaterMeterUrl,url:info.hotWaterMeterUrl})
                        }
                        // 电表号
                        that.dotOne3 = info.wattMeterCode
                        // 电表底数
                        that.dotTwo3 = info.wattMeterNumber
                        if(info.wattMeterCodeType=='1'){
                            that.type3.name = '联合收费处'
                            that.type3.value = '1'
                        } else {
                            that.type3.name = '电表号'
                            that.type3.value = '0'
                        }
                        // 电表底数图片
                        if(info.wattMeterUrl){
                            that.waterMeterFileList3.push({content:info.wattMeterUrl,url:info.wattMeterUrl})
                        }
                        // 燃气表号
                        that.dotOne4 = info.gasMeterCode
                        // 燃气表底数
                        that.dotTwo4 = info.gasMeterNumber
                        if(info.gasMeterCodeType=='1'){
                            that.type4.name = '联合收费处'
                            that.type4.value = '1'
                        } else {
                            that.type4.name = '燃气表号'
                            that.type4.value = '0'
                        }
                        that.description = info.description
                        // 燃气表底数图片
                        if(info.gasMeterUrl){
                            that.waterMeterFileList4.push({content:info.gasMeterUrl,url:info.gasMeterUrl})
                        }

                        //配置列表
                        let list = info.contractPropertyDetail
                        if(list){
                            that.houseConfigurationList = []
                            for(let i=0;i<list.length;i++){
                                let name = list[i].item_id
                                let count = list[i].itemCount
                                let desc = list[i].itemDescription
                                let imgList = []
                                let type = list[i].type
                                let title = list[i].itemTitle
                                let itemName = list[i].itemName
                                for(let j=0;j<list[i].photoList.length;j++){
                                    imgList.push({content:list[i].photoList[j].path,url:list[i].photoList[j].path})
                                }
                                that.houseConfigurationList.push({name:name,count:count,desc:desc,houseFileList:imgList,type:type,configurationInfoList:[],
                                    goodsName:itemName,goodsAllocationClassName:title})
                                that.selectChange(i)
                            }
                        }
                        if(info.videoPath){
                            that.videourl=info.videoPath
                        }
                        if(info.videoId){
                            that.videoId=info.videoId
                        }
                    })
                })
            },

            initData() {
                let that = this
                let initData = {
                    itemType: 1
                }
                queryAllocationClass(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.typeInfoList = response.data.data.data
                    })
                })
            },

            //物品配置图片多选上传
            // afterReadTest(file){
            //     // this.loadingFlag = true;
            //     let fileLength = parseInt(file.length) - parseInt(1)
            //     if(file.length > 1){
            //         for(let i = 0; i<file.length;i++){
            //             let fileDetail = file[i];
            //             this.afterRead(fileDetail,i,fileLength);
            //         }
            //     }else{
            //         this.afterRead(file);
            //     }
            // },
            afterReadTest(file){
                // this.loadingFlag = true;
                let fileLength = parseInt(file.length) - parseInt(1)
                if(file.length > 1){
                    for(let i = 0; i<file.length;i++){
                        let fileDetail = file[i];
                        dealImage(fileDetail,fileDetail.content,400,this.afterRead,i,fileLength);
                    }
                }else{
                    dealImage(file,file.content,400,this.afterRead);
                }
            },

            afterRead(file,base64Str,i,fileLength){
                let that = this
                let initData = {
                    base64: base64Str
                }
                that.loadingFlag = true,
                    base64Upload(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            debugger
                            file.content = response.data.data.path
                            // that.loadingFlag = false
                            if(i == fileLength){
                                that.loadingFlag = false
                            }
                        })
                    })
            },
            beforeReadTestVideo(file){
                this.uploadProgressBar=0
                this.uploadVedio=true
                return true
            },
            afterReadTestVideo(file){          
                if(file.file.size>5368709120){
                    this.waterMeterFileList5=[]
                    responseUtil.alertMsg(that,'上传视频太大，视频需小于5G')
                    return
                }
                var that=this
                var initData={}
                updateVideo(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.afterReadALi(response.data.data,file.file)
                    })
                })
            },
            afterReadALi(data,file){
                let that=this
                var regionName=data.END_POINT
                let client = new OSS({
                   // 阿里云账号AccessKey拥有所有API的访问权限，风险很高。强烈建议您创建并使用RAM用户进行API访问或日常运维，请登录RAM控制台创建RAM用户。
                   accessKeyId: data.ACCESS_ID,
                   accessKeySecret: data.ACCESS_KEY,
                   // 填写Bucket名称。
                   bucket: data.bucketName,//'himitest',
                   region: regionName.substring(7,21)//'oss-cn-beijing',
                });
                let yy = new Date().getFullYear();
                let mm = new Date().getMonth()+1;
                let fileName = "video/"+yy+'/'+mm+"/"+file.name
                // 'object'填写从OSS下载的object名称，即不包括Bucket名称在内的Object的完整路径。
                // 'localfile'填写下载到本地文件的完整路径。
                const progress = (p, _checkpoint) => {
                    // Object的上传进度。
                    that.uploadProgressBar=parseInt(100*p) 
                    // 分片上传的断点信息。
                }
                client.multipartUpload(fileName, file,{progress}).then(function (r1) {
                   that.videourl=r1.res.requestUrls[0].split('?')[0]
                   that.uploadVedio=false
                }).then(function (r2) {
                   //console.log('get success: %j', r2);
                }).catch(function (err) {
                    responseUtil.alertMsg(that,'视频上传失败')
                    that.uploadVedio=false
                   //console.error('error: %j', err);
               });                
                /*client.put(fileName, file).then(function (r1) {
                   //that.waterMeterFileList5[0].content=r1.res.requestUrls[0]
                   that.videourl=r1.res.requestUrls[0]
                   console.log(r1)
                   console.log(that.videourl)
                }).then(function (r2) {
                   //console.log('get success: %j', r2);
                }).catch(function (err) {
                   //console.error('error: %j', err);
               });*/
            },
            //点击后退方法
            onClickLeft(){
                this.$router.go(-1)
            },
            //房屋配置里数量点击增加与减少
            sub(i){
                let count = parseInt(this.houseConfigurationList[i].count)
                if(count<=1){
                    responseUtil.alertMsg(this,'物品数量不能小于1')
                    return
                }
                count--
                this.houseConfigurationList[i].count = count
            },
            add(i){
                let count = parseInt(this.houseConfigurationList[i].count)
                count++
                this.houseConfigurationList[i].count = count
            },
            //    新增条目的点击方法
            addInfo(){
                this.houseConfigurationList.push({count:1,
                    type: '',
                    name:'',
                    configurationInfoList :[],
                    //房屋图片的List
                    houseFileList : [],
                    desc: '',
                    goodsName:'',
                    goodsAllocationClassName:''
                });
            },
            //删除条目的点击方法
            cancelBtn(i){
                this.houseConfigurationList.splice(i,1);  //删除index为i,位置的数组元素
                this.index = 0
            },

            //检测能不能保存物业交割单的校验方法
            canSaveProperty(){
                let that = this
                if(that.dotTwo3&&that.dotTwo&&that.dotTwo4){
                    //在进行物品项校验
                    for(let i in that.houseConfigurationList){
                        if(!that.houseConfigurationList[i].goodsAllocationClassName){
                            responseUtil.alertMsg(that,'请输入物品分类')
                            return false
                        }
                        if(!that.houseConfigurationList[i].goodsName){
                            responseUtil.alertMsg(that,'请输入物品名称')
                            return false
                        }
                    }
                    return true
                } else {
                    // if(!that.dotOne3){
                    //     responseUtil.alertMsg(that,'请输入电表号')
                    //     return false
                    // }
                    if(!that.dotTwo3){
                        responseUtil.alertMsg(that,'请输入电表底数')
                        return false
                    }
                    // if(!that.dotOne){
                    //     responseUtil.alertMsg(that,'请输入水表号')
                    //     return false
                    // }
                    if(!that.dotTwo){
                        responseUtil.alertMsg(that,'请输入水表底数')
                        return false
                    }
                    // if(!that.dotOne4){
                    //     responseUtil.alertMsg(that,'请输入燃气表号')
                    //     return false
                    // }
                    if(!that.dotTwo4){
                        responseUtil.alertMsg(that,'请输入燃气表底数')
                        return false
                    }
                    /*if(!that.videourl){
                        responseUtil.alertMsg(that,'请上传视频')
                        return false
                    }
                    return false*/
                }
            },

            //保存按钮促发事件
            saveInfo(){
                let that = this
                //保存之前先判断符不符合保存标准否则直接返回
                let canSave = that.canSaveProperty()
                if(!canSave) return
                let initData = {}
                //合同id
                initData.contractId = that.contractId
                // 员工id
                initData.addStaff_id = getStaffId()
                // 水表号
                initData.waterMeterCode = that.dotOne
                // 水表底数
                initData.waterMeterBase = that.dotTwo
                // 水表底数图片
                if(that.waterMeterFileList[0])
                    initData.waterMeterAcc_url = that.waterMeterFileList[0].content
                // 热水表号
                initData.hotWaterMeterCode = that.dotOne2
                // 热水表底数
                initData.hotwaterMeterBase = that.dotTwo2
                // 热水表底数图片
                if(that.waterMeterFileList2[0])
                    initData.hotWaterMeterAcc_url = that.waterMeterFileList2[0].content
                // 电表号
                initData.wattMeterCode = that.dotOne3
                // 电表底数
                initData.meterBase = that.dotTwo3
                // 电表底数图片
                if(that.waterMeterFileList3[0])
                    initData.wattMeterAcc_url = that.waterMeterFileList3[0].content
                // 燃气表号
                initData.gasMeterCode = that.dotOne4
                // 燃气表底数
                initData.gasMeter = that.dotTwo4
                // 燃气表底数图片
                if(that.waterMeterFileList4[0])
                    initData.gasMeterAcc_url = that.waterMeterFileList4[0].content
                /*if(that.waterMeterFileList5[0]){
                    initData.picList={}
                    initData.picList.path=that.waterMeterFileList5[0].content
                }*/
                if(that.videourl){
                    initData.picList=[]
                    initData.picList[0]={}
                    initData.picList[0].path=that.videourl
                }
                //描述
                initData.description = that.description
                //添加一个状态信息表明此物业交割单是从合同还是租约变更那里添加的数据
                initData.type = that.propertyType
                //添加历史合同id
                initData.contractHistoryId = that.contracthistoryId
                initData.contractPropertyId = that.contractPropertyId

                initData.waterMeterCodeType = that.type1.value
                initData.hotWaterMeterCodeType = that.type2.value
                initData.wattMeterCodeType = that.type3.value
                initData.gasMeterCodeType = that.type4.value

                //配置列表
                initData.configurelist = []
                for(let i=0;i<that.houseConfigurationList.length;i++){
                    let data = {}
                    data.item_id = that.houseConfigurationList[i].name
                    data.desc = that.houseConfigurationList[i].desc
                    data.itemCount = that.houseConfigurationList[i].count
                    data.imgList = []
                    for(let j=0;j<that.houseConfigurationList[i].houseFileList.length;j++)
                        data.imgList.push({imgUrl: that.houseConfigurationList[i].houseFileList[j].content})
                    initData.configurelist.push(data)
                }
                //console.log(initData)
                // 改变保存按钮状态，防止二次点击
                that.saveingStatus = true
                addContractproperty(initData).then(function (response) {
                    that.saveingStatus = false
                    responseUtil.dealResponse(that, response, () => {
                        if(response.data.code==0){
                            responseUtil.alertMsg(that,'添加成功')
                            that.$router.go(-1)
                        }
                    })
                })
            },
            selectChange(val){
                //this.selectTemp=this.houseConfigurationList[val].select
                let that = this
                let initData = {
                    goodsAllocationClass_id: that.houseConfigurationList[val].type
                }
                queryAllocation(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.houseConfigurationList[val].configurationInfoList = response.data.data.data
                    })
                })
            }
        }
    }

</script>

<style lang="less" scoped >

    .addProperty{
        overflow: hidden;
        width: 100%;
    }
    //水表交割标题
    .waterMeterTitle{
        width: 92%;
        margin: 0 auto;
        font-size: 13px;
        color: #D7655A;
    }
    /*公共分割线*/
    .ruleOne{
        width: 100%;
        border: 0.5px solid #e6e6e6;
        /*margin: 10px auto;*/
    }
    /*公共竖线的div*/
    .rule{
        font-size: 16px;
        margin-right: 10px;
        color: #e6e6e6;
        height: 22px;
    }
    /*   公共 上传图片*/
    .upLoadImg{
        margin: 15px 15px 0px;
    }
    .deleteVideo{
        float:left;
        font-size: 12px;
        color: #D7655A;

    }    
    .upLoadText{
        font-size: 12px;
        color: #D7655A;
        text-align: right;
        width: 95%;
    }
    /*公共的dot*/
    .grayDot{
        border: 3px solid #999999;
        border-radius: 50%;
        margin-right: 10px;
        /*margin-left: 15px;*/
    }
    .redDot{
        border: 3px solid #D7655A;
        border-radius: 50%;
        margin-right: 10px;
    }
    /*placeholder默认字体色*/
    input::placeholder{
        color: #e6e6e6;
    }
    /*/*placeholder默认字体色*/
    textarea::placeholder{
        color: #e6e6e6;
    }

    .downImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
    }
    .upImage{
        width: 12px;
        height: 12px;
        margin-right: 10px;
        transform: rotateX(180deg);
    }
    .cashierTextg11{
        font-size: 15px;
        font-weight: bold;
        margin-right: 10px;
        /*width: 350px;*/
        white-space: nowrap;
        border: none;
        height: 20px;
    }
    /*交割信息表*/
    .waterMeterDeliveryInfo{
        width: 92%;
        margin: 5px auto 30px;
        background-color: white;
        border-radius: 8px;
        /*联合收费处和水表底数DIV*/
        padding-bottom: 10px;
        .cashier{
            display: flex;
            align-items: center;
            height: 50px;
            margin: 0 15px;
            .cashierText{
                font-size: 15px;
                font-weight: bold;
                margin-right: 10px;
                /*width: 350px;*/
                white-space: nowrap;
            }
            .cashierTextg{
                font-size: 15px;
                font-weight: bold;
                margin-right: 10px;
                /*width: 350px;*/
                white-space: nowrap;
                border: none;
                /*margin-left: -4px;*/
                height: 20px;
            }
            .inputNum{
                font-size: 15px;
                /*color: #999999;*/
                background-color: white;
                /*color: white;*/
                border: none;
                height: 30px;
            }
        }
    }

    .waterMeterDeliveryInfoVideo{
        width: 92%;
        position:relative;
        margin: 5px auto 30px;
        background-color: white;
        border-radius: 8px;
        /*联合收费处和水表底数DIV*/
        padding-bottom: 10px;
        .cashier{
            display: flex;
            align-items: center;
            height: 50px;
            margin: 0 15px;
            .cashierText{
                font-size: 15px;
                font-weight: bold;
                margin-right: 10px;
                /*width: 350px;*/
                white-space: nowrap;
            }
            .cashierTextg{
                font-size: 15px;
                font-weight: bold;
                margin-right: 10px;
                /*width: 350px;*/
                white-space: nowrap;
                border: none;
                /*margin-left: -4px;*/
                height: 20px;
            }
            .inputNum{
                font-size: 15px;
                /*color: #999999;*/
                background-color: white;
                /*color: white;*/
                border: none;
                height: 30px;
            }

        }
    }
    .van-loading{
        position:absolute;
        top:25px;
        left:25px;
    }
    .upLoadImg-vedioHide{
         visibility:hidden;
         display:block !important;
         margin:  15px 15px 0px;
    }    
    /*    房屋配置信息表*/
    .houseConfiguration {
        width: 92%;
        display: flex;
        margin: 5px auto 20px;
        /*取消按钮*/

        .cancelBtn {
            width: 22px;
            height: 20px;
            border-radius: 50%;
            background-color: #D7655A;
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 13px;
            .cancelImg {
                height: 2px;
                width: 12px;
                background-color: white;
            }
        }
        /*    配置详情*/
        .houseDetail{
            width: 100%;
            background-color: white;
            margin-left: 5px;
            border-radius: 8px;
            /*配置名称*/
            .configuration{
                display: flex;
                align-items: center;
                height: 50px;
                margin: 0 15px;
                /*名称的DIV              数量的DIV*/
                .configurationName,.configurationCount{
                    font-size: 15px;
                    font-weight: bold;
                    margin-right: 10px;
                    white-space: nowrap;
                }
                /*select菜单*/
                .configurationSelect{
                    background-color: white;
                    width: 75%;
                    border: none;
                    height: 35px;
                    font-size: 15px;
                    text-align: center;
                    line-height: 35px;
                }
                /*点击用的加号*/
                .changeCount{
                    width: 30px;
                    height: 30px;
                    text-align: center;
                    background-color: #eee;
                    color: #ddd;
                    line-height: 30px;
                    border-radius: 5px;
                }
                /*数量后面的占位DIV*/
                .placeholderDiv{
                    width:115px ;
                    input {
                        width: 90px;
                        height: 32.5px;
                        /*line-height: 40px;*/
                        font-size: 15px;
                        border: none;
                    }
                }
                /*数量值的DIV*/
                .count{
                    text-align: center;
                    width: 20px;
                    color: #D7655A;
                    font-size: 15px;
                    margin: 0 10px;
                    height: 30px;
                    line-height: 30px;
                    border: none;
                    background-color: white;
                }
            }
            /*    备注文本域*/
            .textareaRemarks{
                width: 82%;
                height: 50px;
                margin: 15px auto;
                border-radius: 8px;
                display: block;
                background-color: #f7f7f7;
                border: none;
                font-size: 13px;
                color: #999;
                padding: 15px 15px;
                resize: none;
            }
            /*房屋配置里的   请务必上传*/
            .upLoadText{
                margin-bottom: 10px;
            }
        }
    }

    .addPropertyDetail{
        width: 92%;
        margin: 0rem auto 0.25rem;
        background-color: white;
        border-radius: 0.21333rem;
        padding-bottom: 0.05rem;
        padding-top: 0.05rem;
        /*    备注文本域*/
        .textareaRemarks{
            width: 82%;
            height: 50px;
            margin: 15px auto;
            border-radius: 8px;
            display: block;
            background-color: #f7f7f7;
            border: none;
            font-size: 13px;
            color: #999;
            padding: 15px 15px;
            resize: none;
        }
    }


    /*新增条目*/
    .newAdd{
        width: 25%;
        margin-left: 250px;
        margin-bottom: 20px;
        color: #D7655A;
        font-size: 13px;
        text-align: right;
    }
    /*最后保存的按钮*/
    .graySaveBtn,.redsaveBtn{
        width: 92%;
        height: 50px;
        display:block;
        margin: 20px auto 60px;
        border-radius: 8px;
        border: none;
        font-size: 14px;
        color: white;
    }
    /*按钮默认灰色*/
    .graySaveBtn{
        background-color:#999;
    }
    /*按钮满足条件变成蓝色*/
    .redsaveBtn{
        background-image: linear-gradient(to right,#FFC274,#FF5D3B);
    }
    .remove_video{

        //font-size:14px;

    }
</style>
